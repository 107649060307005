var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-banner-editor card"},[_c('fd-form',{on:{"submit":_vm.onSubmit}},[_c('modal-content',{attrs:{"wrapperCSS":"w-100"}},[_c('modal-header',[_c('h3',{staticClass:"p-3"},[_vm._v(_vm._s(_vm.isEdit ? "Edit" : "Create")+" App Banner")])]),_c('modal-body',{staticClass:"p-2"},[_c('p',{staticClass:"bg-main fg-white mx-1 mb-2 p-2"},[_vm._v(" The recommended aspect ratio is "),_c('span',{staticClass:"font-bold"},[_vm._v("16:9")])]),_c('image-uploader',{staticClass:"col-12 px-1 mb-2",attrs:{"label":"Image","multiple":false,"required":"","cropper-enabled":"","stencil-props":{
            aspectRatio: 16 / 9
          }},on:{"loading":function (val) { return (_vm.isLoading = val); },"error":function (error, imageName) {
              _vm.reportError(error, 'Upload App Banner');
            }},model:{value:(_vm.form.image),callback:function ($$v) {_vm.$set(_vm.form, "image", $$v)},expression:"form.image"}}),_c('hr',{staticClass:"my-4"}),_c('div',{staticClass:"row"},[_c('fd-input',{staticClass:"col-12 sm-col-6 md-col-3 px-1 mb-2",attrs:{"label":"Title (Remark Use)","name":"bannerTitle","type":"text","validators":[_vm.validator.required]},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('fd-select',{staticClass:"col-12 sm-col-6 md-col-3 px-1 mb-2",attrs:{"label":"Banner Action Type","options":_vm.bannerTypeOptions,"optionValue":function (val) { return val; },"optionLabel":function (val) { return val; },"validators":[_vm.validator.required]},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1),_c('div',{staticClass:"row"},[(_vm.isWebviewType)?_c('fd-input',{staticClass:"col-12 sm-col-6 md-col-3 px-1 mb-2",attrs:{"label":"Webview URL","name":"purpose","type":"text","validators":[_vm.validator.required, _vm.validator.website]},model:{value:(_vm.form.payload.url),callback:function ($$v) {_vm.$set(_vm.form.payload, "url", $$v)},expression:"form.payload.url"}}):_vm._e(),(_vm.isProjectType)?_c('div',{staticClass:"col-12 mt-3 mb-5"},[_c('h5',{staticClass:"px-1 mb-2"},[_vm._v("Project")]),_c('v-select',{staticClass:"col-12 sm-col-6 md-col-4 px-1",attrs:{"options":_vm.projects,"loading":_vm.isProjectLoading,"label":"name","placeholder":"Search a Project","required":""},on:{"update:loading":function($event){_vm.isProjectLoading=$event},"search":_vm.searchProject},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
            var name = ref.name;
            var mainPhoto = ref.mainPhoto;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"select-project-thumbnail",attrs:{"src":_vm.$getFileURL(mainPhoto[0]),"alt":name}}),_c('b',{staticClass:"ml-2"},[_vm._v(_vm._s(name))])])]}},{key:"option",fn:function(ref){
            var name = ref.name;
            var mainPhoto = ref.mainPhoto;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"select-project-thumbnail",attrs:{"src":_vm.$getFileURL(mainPhoto[0]),"alt":name}}),_c('b',{staticClass:"ml-2"},[_vm._v(_vm._s(name))])])]}},{key:"no-options",fn:function(){return [_vm._v(" This is no available project option. ")]},proxy:true},{key:"open-indicator",fn:function(ref){
            var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('i',{staticClass:"px-1 fas fa-caret-down"})])]}},{key:"list-footer",fn:function(){return [_c('InfiniteLoading',{on:{"infinite":_vm.handleInfiniteLoading}},[_c('span',{attrs:{"slot":"no-more"},slot:"no-more"})])]},proxy:true}],null,false,676056562),model:{value:(_vm.form.payload.project),callback:function ($$v) {_vm.$set(_vm.form.payload, "project", $$v)},expression:"form.payload.project"}})],1):_vm._e()],1)],1),_c('modal-footer',{staticClass:"d-flex justify-content-end p-2"},[_c('fd-button',{staticClass:"medium main",attrs:{"type":"submit","isLoading":_vm.isFormLoading,"loadingEnabled":"","disabled":_vm.isFormLoading}},[_vm._v(" "+_vm._s(_vm.isEdit ? "Update" : "Create")+" ")]),_c('fd-button',{staticClass:"medium ml-2",on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }